* {
    transition: all .3s;
    margin:0;
    padding:0;
}

Link {
    text-decoration: none;
}

p, h1, h2, h3, h4, h5, h6 {
    margin-top:18px;
    margin-bottom:18px;
}

#root {
    margin-left:0px;
}

body {
    background-color: black;
    margin: 0;
    
}

nav {
    height:100px;
    border-bottom:10px solid white;
    background-color: black;

}

nav img {
    float:left;
    height:100px;
}

.navLink {
    width:100px;
    height:50px;
    border:1px solid white;
    float:right;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right:15px;
    margin-top:20px;
    transform: skew(-20deg, 0deg);
}

.navLink a {
    text-decoration: none;
    color:white;
}

footer {
    width:100%;
    height:300px;
    margin-top: 100px;
    background-color: #353535;
}

h1 {
    color:white;
    font-size: 50px;
    text-align: center;
}

.error {
    font-size: 200px;
}

.errorP {
    width:70%;
    height:200px;
    background-color: black;
    border:10px solid white;
    text-align: center;
    color:white;
    margin: auto;
    font-size: 20px;
    text-decoration: none;
}

.errorP {
    padding:10px;
    padding-top:50px
}

.errorButton {
    width:50%;
    height:50px;
    color:white;
    background-color:black;
    border:1px solid white;
    margin:auto;
    margin-top:15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorButton:hover {
    background-color: white;
    color:black
}

.logoCont {
    width:50%;
    margin:auto;
}

.logoCont img {
    display:block;
    margin:auto;
    width:50%;

}

.cardHolderService {
    width:90%;
    border:0px solid white;
    background-color:black;
    margin:auto;
    display: flex;
    justify-content: space-between;
}

.serviceCard {
    width:calc(33% - 60px);
    color:white;
    text-align: center;
    border:10px solid darkgrey;
    border-radius: 10px;
    border-style:double;
    background-color: black;
    display: inline-block;
    margin:20px;
    padding:25px;

}

.cardHolderCast {
    width:90%;
    margin:auto;
    display: flex;
    justify-content: space-between;

}

.castCardFull {
    width:calc(50% - 60px) !important;
    max-width:calc(50% - 60px);
    color:white;
    background-color: black;
    border:10px solid white;
    border-radius: 10px;
    display: inline-flex;
    margin:20px;
    padding:10px;
    overflow: hidden;
}

.castCardEmpty {
    width:calc(50% - 60px) !important;
    max-width:calc(50% - 60px);
    color:white;
    background-color: black;
    border:10px solid #353535;
    border-radius: 10px;
    border-style: dotted;
    display: inline-flex;
    margin:20px;
    padding:10px;
    overflow: hidden;
}

.castCardEmpty img,
.castCardFull img {
    float:right;
    width:50px;
    height:50px;
    margin:10px;
}

.castCardEmpty img {
    border:3px solid #353535;
    border-style: dashed;
    border-radius: 5px;
}

.castCardFull img {
    border: 3px solid white;
    border-radius: 5px;
}

.card h3 {
    width:96%;

    margin-right:4%;
    display: inline-block;
    text-align: right;
    margin-right:40px;
    font-size: 40px;
    transform: translateY(-75px);
}

.castCardEmpty h3 {
    background-color: #353535;
    width:80%;
    border-radius: 200px;
    margin-left:19%
}

.castCardEmpty p {
    color:#353535;
}


.card p {
    height:0px;
    transform: translateY(-75px);
    display: block;
    word-wrap: break-word;
}
.card {

    display: flex;
    flex-direction: column;
}

.cardCreate {
    width:80%;
    background-color: black;
    border:10px solid white;
    border-style: double;
    margin:auto;
    border-radius: 10px;
    margin-top: 50px;

}

.cardCreate h2 {
    color:white;
    font-size: 50px;
    text-align: center;
}

.cardCreate img {
    height:20%;
    width:20%;
    padding:10px;
    margin:10px;
    border-radius: 10px;
    border:5px solid white
    
}

.cardCreate p {
    float:right;
    color:white;
    width:70%;

}

.footerPFP {
    width:50px;
    height:50px;
    margin:10px;
    border:3px solid white;
    border-radius: 10px;
}

.calculator {
    width:60%;
    margin:auto;
    border:3px solid white;
}

.calculator tr,
.calculator th {
    border:1px solid white;
    color:white;
    padding:5px;
    height:50px;
    width:33%;
}

.calculator input {
    width:100%;
    height:100%;
    border:5px solid white;
}

.typeNumber {
    width:100%;
    height:100%;
    border:5px solid white;
    font-size: 50px;
}

.mainPara {
    color:white;
    text-align: center;
}

.warning {
    width:60%;
    height:65px;
    border: 10px solid red;
    border-radius: 10px;
    border-style: double;
    margin:auto;
    justify-content: space-between;
    align-items: center;
    display:flex;
    padding:5px;
    box-shadow: 0px 0px 30px red;
    animation: warningPulse 10s linear infinite;
}

.warning img {
    height:50px;
}

.warning p {
    color:white;
}

@keyframes warningPulse {
    0%, 30%, 70%, 100% {
        box-shadow: 0px 0px 30px red;
    }

    50% {
        box-shadow: 0px 0px 30px black;
    }
    
}